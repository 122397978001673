.notFoundContainer {
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f5f5f5;
   padding: 1rem;
   text-align: center;

   .content {
      background: var(--Neutral-White-White, #fff);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      width: 462px;
      border-radius: 12px;
      padding: 32px;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      .wrapperText {
         display: flex;
         flex-direction: column;
         gap: 8px;
         h2 {
            color: var(--Neutral-Black-Black, #000);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 137.5%;
         }

         p {
            color: var(--Neutral-Slate-600, #475569);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
         }
      }

      img {
         border-radius: 12px;
      }
   }
   a {
      width: 100%;
   }
}
